import Rus from "@/locale/rus";
import Eng from "@/locale/eng";
import Deu from "@/locale/deu";
import Kaz from "@/locale/kaz";

class i18n {
    static language = "ru";
    static content = {
        ru: Rus,
        en: Eng,
        dt: Deu,
        kz: Kaz
    };

    static get actor_spectacles() {
        if (i18n.content[i18n.language].actor_spectacles) {
            return i18n.content[i18n.language].actor_spectacles;
        }
        return Rus.actor_spectacles;
    }

    static get actors_all_actors() {
        if (i18n.content[i18n.language].actors_all_actors) {
            return i18n.content[i18n.language].actors_all_actors;
        }
        return Rus.actors_all_actors;
    }

    static get culture_news_theater_news() {
        if (i18n.content[i18n.language].culture_news_theater_news) {
            return i18n.content[i18n.language].culture_news_theater_news;
        }
        return Rus.culture_news_theater_news;
    }

    static get culture_news_about() {
        if (i18n.content[i18n.language].culture_news_about) {
            return i18n.content[i18n.language].culture_news_about;
        }
        return Rus.culture_news_about;
    }

    static get culture_news_tag() {
        if (i18n.content[i18n.language].culture_news_tag) {
            return i18n.content[i18n.language].culture_news_tag;
        }
        return Rus.culture_news_tag;
    }

    static get home_actors() {
        if (i18n.content[i18n.language].home_actors) {
            return i18n.content[i18n.language].home_actors;
        }
        return Rus.home_actors;
    }

    static get home_actors_view_all() {
        if (i18n.content[i18n.language].home_actors_view_all) {
            return i18n.content[i18n.language].home_actors_view_all;
        }
        return Rus.home_actors_view_all;
    }

    static get home_repertoire() {
        if (i18n.content[i18n.language].home_repertoire) {
            return i18n.content[i18n.language].home_repertoire;
        }
        return Rus.home_repertoire;
    }

    static get home_repertoire_view_all() {
        if (i18n.content[i18n.language].home_repertoire_view_all) {
            return i18n.content[i18n.language].home_repertoire_view_all;
        }
        return Rus.home_repertoire_view_all;
    }

    static get home_news() {
        if (i18n.content[i18n.language].home_news) {
            return i18n.content[i18n.language].home_news;
        }
        return Rus.home_news;
    }

    static get home_news_view_all() {
        if (i18n.content[i18n.language].home_news_view_all) {
            return i18n.content[i18n.language].home_news_view_all;
        }
        return Rus.home_news_view_all;
    }

    static get home_news_toggle_all() {
        if (i18n.content[i18n.language].home_news_toggle_all) {
            return i18n.content[i18n.language].home_news_toggle_all;
        }
        return Rus.home_news_toggle_all;
    }

    static get home_news_toggle_culture() {
        if (i18n.content[i18n.language].home_news_toggle_culture) {
            return i18n.content[i18n.language].home_news_toggle_culture;
        }
        return Rus.home_news_toggle_culture;
    }

    static get home_news_toggle_theater() {
        if (i18n.content[i18n.language].home_news_toggle_theater) {
            return i18n.content[i18n.language].home_news_toggle_theater;
        }
        return Rus.home_news_toggle_theater;
    }

    static get home_subscribe() {
        if (i18n.content[i18n.language].home_subscribe) {
            return i18n.content[i18n.language].home_subscribe;
        }
        return Rus.home_subscribe;
    }

    static get home_subscribe_button() {
        if (i18n.content[i18n.language].home_subscribe_button) {
            return i18n.content[i18n.language].home_subscribe_button;
        }
        return Rus.home_subscribe_button;
    }

    static get home_subscribe_hint() {
        if (i18n.content[i18n.language].home_subscribe_hint) {
            return i18n.content[i18n.language].home_subscribe_hint;
        }
        return Rus.home_subscribe_hint;
    }

    static get home_subscribe_error() {
        if (i18n.content[i18n.language].home_subscribe_error) {
            return i18n.content[i18n.language].home_subscribe_error;
        }
        return Rus.home_subscribe_error;
    }

    static get home_subscribe_success() {
        if (i18n.content[i18n.language].home_subscribe_success) {
            return i18n.content[i18n.language].home_subscribe_success;
        }
        return Rus.home_subscribe_success;
    }

    static get home_subscribe_name_placeholder() {
        if (i18n.content[i18n.language].home_subscribe_name_placeholder) {
            return i18n.content[i18n.language].home_subscribe_name_placeholder;
        }
        return Rus.home_subscribe_name_placeholder;
    }

    static get not_found_text() {
        if (i18n.content[i18n.language].not_found_text) {
            return i18n.content[i18n.language].not_found_text;
        }
        return Rus.not_found_text;
    }

    static get not_found_home_name() {
        if (i18n.content[i18n.language].not_found_home_name) {
            return i18n.content[i18n.language].not_found_home_name;
        }
        return Rus.not_found_home_name;
    }

    static get production_team_mobile() {
        if (i18n.content[i18n.language].production_team_mobile) {
            return i18n.content[i18n.language].production_team_mobile;
        }
        return Rus.production_team_mobile;
    }

    static get production_team_cv() {
        if (i18n.content[i18n.language].production_team_cv) {
            return i18n.content[i18n.language].production_team_cv;
        }
        return Rus.production_team_cv;
    }

    static get production_team_portfolio() {
        if (i18n.content[i18n.language].production_team_portfolio) {
            return i18n.content[i18n.language].production_team_portfolio;
        }
        return Rus.production_team_portfolio;
    }

    static get repertoire_is_for() {
        if (i18n.content[i18n.language].repertoire_is_for) {
            return i18n.content[i18n.language].repertoire_is_for;
        }
        return Rus.repertoire_is_for;
    }

    static get repertoire_soon() {
        if (i18n.content[i18n.language].repertoire_soon) {
            return i18n.content[i18n.language].repertoire_soon;
        }
        return Rus.repertoire_soon;
    }

    static get spectacle_buy_a_ticket() {
        if (i18n.content[i18n.language].spectacle_buy_a_ticket) {
            return i18n.content[i18n.language].spectacle_buy_a_ticket;
        }
        return Rus.spectacle_buy_a_ticket;
    }

    static get spectacle_location() {
        if (i18n.content[i18n.language].spectacle_location) {
            return i18n.content[i18n.language].spectacle_location;
        }
        return Rus.spectacle_location;
    }

    static get spectacle_date() {
        if (i18n.content[i18n.language].spectacle_date) {
            return i18n.content[i18n.language].spectacle_date;
        }
        return Rus.spectacle_date;
    }

    static get spectacle_time() {
        if (i18n.content[i18n.language].spectacle_time) {
            return i18n.content[i18n.language].spectacle_time;
        }
        return Rus.spectacle_time;
    }

    static get spectacle_price() {
        if (i18n.content[i18n.language].spectacle_price) {
            return i18n.content[i18n.language].spectacle_price;
        }
        return Rus.spectacle_price;
    }

    static get spectacle_actors() {
        if (i18n.content[i18n.language].spectacle_actors) {
            return i18n.content[i18n.language].spectacle_actors;
        }
        return Rus.spectacle_actors;
    }

    static get spectacles_text() {
        if (i18n.content[i18n.language].spectacles_text) {
            return i18n.content[i18n.language].spectacles_text;
        }
        return Rus.spectacles_text;
    }

    static get theater_news_culture_news() {
        if (i18n.content[i18n.language].theater_news_culture_news) {
            return i18n.content[i18n.language].theater_news_culture_news;
        }
        return Rus.theater_news_culture_news;
    }

    static get theater_news_about() {
        if (i18n.content[i18n.language].theater_news_about) {
            return i18n.content[i18n.language].theater_news_about;
        }
        return Rus.theater_news_about;
    }

    static get theater_news_tag() {
        if (i18n.content[i18n.language].theater_news_tag) {
            return i18n.content[i18n.language].theater_news_tag;
        }
        return Rus.theater_news_tag;
    }

    static get today_spectacle_text() {
        if (i18n.content[i18n.language].today_spectacle_text) {
            return i18n.content[i18n.language].today_spectacle_text;
        }
        return Rus.today_spectacle_text;
    }

    static get unsubscribe_heading() {
        if (i18n.content[i18n.language].unsubscribe_heading) {
            return i18n.content[i18n.language].unsubscribe_heading;
        }
        return Rus.unsubscribe_heading;
    }

    static get unsubscribe_button() {
        if (i18n.content[i18n.language].unsubscribe_button) {
            return i18n.content[i18n.language].unsubscribe_button;
        }
        return Rus.unsubscribe_button;
    }

    static get unsubscribe_error() {
        if (i18n.content[i18n.language].unsubscribe_error) {
            return i18n.content[i18n.language].unsubscribe_error;
        }
        return Rus.unsubscribe_error;
    }

    static get unsubscribe_success() {
        if (i18n.content[i18n.language].unsubscribe_success) {
            return i18n.content[i18n.language].unsubscribe_success;
        }
        return Rus.unsubscribe_success;
    }

    static get vacancy_detail_button() {
        if (i18n.content[i18n.language].vacancy_detail_button) {
            return i18n.content[i18n.language].vacancy_detail_button;
        }
        return Rus.vacancy_detail_button;
    }

    static get vacancy_popup_heading() {
        if (i18n.content[i18n.language].vacancy_popup_heading) {
            return i18n.content[i18n.language].vacancy_popup_heading;
        }
        return Rus.vacancy_popup_heading;
    }

    static get vacancy_send_error() {
        if (i18n.content[i18n.language].vacancy_send_error) {
            return i18n.content[i18n.language].vacancy_send_error;
        }
        return Rus.vacancy_send_error;
    }

    static get vacancy_send_success() {
        if (i18n.content[i18n.language].vacancy_send_success) {
            return i18n.content[i18n.language].vacancy_send_success;
        }
        return Rus.vacancy_send_success;
    }

    static get vacancy_popup_your_cv() {
        if (i18n.content[i18n.language].vacancy_popup_your_cv) {
            return i18n.content[i18n.language].vacancy_popup_your_cv;
        }
        return Rus.vacancy_popup_your_cv;
    }

    static get vacancy_popup_send_button() {
        if (i18n.content[i18n.language].vacancy_popup_send_button) {
            return i18n.content[i18n.language].vacancy_popup_send_button;
        }
        return Rus.vacancy_popup_send_button;
    }

    static get vacancy_popup_hint() {
        if (i18n.content[i18n.language].vacancy_popup_hint) {
            return i18n.content[i18n.language].vacancy_popup_hint;
        }
        return Rus.vacancy_popup_hint;
    }

    static get vacancy_popup_name_placeholder() {
        if (i18n.content[i18n.language].vacancy_popup_name_placeholder) {
            return i18n.content[i18n.language].vacancy_popup_name_placeholder;
        }
        return Rus.vacancy_popup_name_placeholder;
    }

    static get vacancy_popup_phone_placeholder() {
        if (i18n.content[i18n.language].vacancy_popup_phone_placeholder) {
            return i18n.content[i18n.language].vacancy_popup_phone_placeholder;
        }
        return Rus.vacancy_popup_phone_placeholder;
    }

    static get vacancy_popup_cv_label() {
        if (i18n.content[i18n.language].vacancy_popup_cv_label) {
            return i18n.content[i18n.language].vacancy_popup_cv_label;
        }
        return Rus.vacancy_popup_cv_label;
    }

    static get vacancy_popup_cv_files_count() {
        if (i18n.content[i18n.language].vacancy_popup_cv_files_count) {
            return i18n.content[i18n.language].vacancy_popup_cv_files_count;
        }
        return Rus.vacancy_popup_cv_files_count;
    }

    static get component_home_repertoire_date() {
        if (i18n.content[i18n.language].component_home_repertoire_date) {
            return i18n.content[i18n.language].component_home_repertoire_date;
        }
        return Rus.component_home_repertoire_date;
    }

    static get component_home_repertoire_time() {
        if (i18n.content[i18n.language].component_home_repertoire_time) {
            return i18n.content[i18n.language].component_home_repertoire_time;
        }
        return Rus.component_home_repertoire_time;
    }

    static get component_home_repertoire_soon() {
        if (i18n.content[i18n.language].component_home_repertoire_soon) {
            return i18n.content[i18n.language].component_home_repertoire_soon;
        }
        return Rus.component_home_repertoire_soon;
    }

    static get component_lab_spoiler_go_to_spectacle() {
        if (i18n.content[i18n.language].component_lab_spoiler_go_to_spectacle) {
            return i18n.content[i18n.language].component_lab_spoiler_go_to_spectacle;
        }
        return Rus.component_lab_spoiler_go_to_spectacle;
    }

    static get component_management_card_contacts() {
        if (i18n.content[i18n.language].component_management_card_contacts) {
            return i18n.content[i18n.language].component_management_card_contacts;
        }
        return Rus.component_management_card_contacts;
    }

    static get component_management_card_email() {
        if (i18n.content[i18n.language].component_management_card_email) {
            return i18n.content[i18n.language].component_management_card_email;
        }
        return Rus.component_management_card_email;
    }

    static get component_spectacle_date_time() {
        if (i18n.content[i18n.language].component_spectacle_date_time) {
            return i18n.content[i18n.language].component_spectacle_date_time;
        }
        return Rus.component_spectacle_date_time;
    }

    static get component_spectacle_date_tickets_in() {
        if (i18n.content[i18n.language].component_spectacle_date_tickets_in) {
            return i18n.content[i18n.language].component_spectacle_date_tickets_in;
        }
        return Rus.component_spectacle_date_tickets_in;
    }

    static get component_spectacle_date_theater_box_office() {
        if (i18n.content[i18n.language].component_spectacle_date_theater_box_office) {
            return i18n.content[i18n.language].component_spectacle_date_theater_box_office;
        }
        return Rus.component_spectacle_date_theater_box_office;
    }

    static get component_spectacle_date_tickets() {
        if (i18n.content[i18n.language].component_spectacle_date_tickets) {
            return i18n.content[i18n.language].component_spectacle_date_tickets;
        }
        return Rus.component_spectacle_date_tickets;
    }

    static get component_spectacle_date_entrance() {
        if (i18n.content[i18n.language].component_spectacle_date_entrance) {
            return i18n.content[i18n.language].component_spectacle_date_entrance;
        }
        return Rus.component_spectacle_date_entrance;
    }

    static get component_spectacle_date_free() {
        if (i18n.content[i18n.language].component_spectacle_date_free) {
            return i18n.content[i18n.language].component_spectacle_date_free;
        }
        return Rus.component_spectacle_date_free;
    }

    static get component_title_link_view() {
        if (i18n.content[i18n.language].component_title_link_view) {
            return i18n.content[i18n.language].component_title_link_view;
        }
        return Rus.component_title_link_view;
    }

    static get component_top_menu_contacts() {
        if (i18n.content[i18n.language].component_top_menu_contacts) {
            return i18n.content[i18n.language].component_top_menu_contacts;
        }
        return Rus.component_top_menu_contacts;
    }
}

export default i18n;