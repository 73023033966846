export default {
    actor_spectacles: "Performances",
    actors_all_actors: "List of all actors of the theater",
    home_actors: "THE ACTORS",
    home_actors_view_all: "more",
    home_repertoire: "Repertoire",
    home_repertoire_view_all: "more",
    not_found_text: "There is no such page, but you may be interested in visiting the following pages:",
    not_found_home_name: "Home",
    repertoire_is_for: "repertoire for",
    repertoire_soon: "soon",
    spectacle_buy_a_ticket: "Ticket",
    spectacle_location: "Venue:",
    spectacle_date: "Date of the event:",
    spectacle_time: "Time of the event:",
    spectacle_price: "Price:",
    spectacle_actors: "The actors",
    spectacles_text: "A complete list of all the performances of the theater",
    component_home_repertoire_date: "Date:",
    component_home_repertoire_time: "Time:",
    component_home_repertoire_soon: "Soon",
    component_lab_spoiler_go_to_spectacle: "More",
    component_management_card_contacts: "Contacts:",
    component_management_card_email: "Email:",
    component_spectacle_date_time: "Time:",
    component_spectacle_date_tickets_in: "Contact our",
    component_spectacle_date_theater_box_office: "box office",
    component_spectacle_date_tickets: "Ticket",
    component_spectacle_date_entrance: "Free",
    component_spectacle_date_free: "entry",
    component_top_menu_contacts: "CONTACTS",
}