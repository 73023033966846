<template>
    <Loader_popup/>
    <Top_menu/>
    <main>
        <router-view v-slot="{ Component }">
            <div :key="$route.path">
                <component :is="Component" :class="{ fade_in_component: component_visible }"/>
            </div>
        </router-view>
    </main>
    <Footer />
    <Light_box />
</template>

<script>
import Loader_popup from "@/components/Loader";
import Top_menu from "@/components/Top_Menu";
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";
import Light_box from "@/components/Light_box.vue";
import "@/assets/css/animation.less";

export default {
    components: {
        Loader_popup,
        Top_menu,
        Footer,
        Light_box,
    },
    computed: {
        ...mapGetters({
            loading: "common/get_loading"
        }),
        component_visible() {
            return !this.loading;
        },
    }
}

</script>

<style lang="less">
@import "assets/css/common";

main {
    min-height: 90vh;
}

.fade_in_component {
    @keyframes fade_in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    animation: fade_in 1s forwards;
}
</style>
