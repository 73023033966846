<template>
    <header :class="{no_top_padding: banner.status, menu_scroll_show: menu_scrolled, menu_scroll_hide: !menu_scrolled, lab: style_lab}">
        <a :href="banner.link" class="banner" v-if="banner.status">
            <span class="content" v-html="banner.text"></span>
        </a>
        <div class="top">
            <div class="contacts">
                <p class="popup_head">
                    <span>{{ i18n.component_top_menu_contacts }}</span>
                    <span class="popup_icon"></span>
                </p>
                <div class="popup left">
                    <span class="popup_icon"></span>
                    <a href="tel:+77778272890">+7-777-827-28-90</a>
                    <a href="mailto:info@nemetski.kz">info@nemetski.kz</a>
                    <div class="popup_socials">
                        <a class="social" href="https://www.instagram.com/nemetski.kz/">
                            <img src="@/assets/images/common/menu_instagram.svg" alt="">
                        </a>
                        <a class="social" href="https://www.facebook.com/nemetski.kz/">
                            <img src="@/assets/images/common/menu_facebook.svg" alt="">
                        </a>
                        <a class="social" href="https://www.youtube.com/channel/UClMjLnw5TNsWnW-q-sRN-uA">
                            <img src="@/assets/images/common/menu_youtube.svg" alt="">
                        </a>
                        <a class="social" href="https://t.me/nemetski">
                            <img src="@/assets/images/common/menu_telegram.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <div class="socials">
                <a class="social" href="https://www.instagram.com/nemetski.kz/">
                    <img src="@/assets/images/common/menu_instagram.svg" alt="" v-if="!style_lab">
                    <img src="@/assets/images/common/menu_instagram_white.svg" alt="" v-else>
                </a>
                <a class="social" href="https://www.facebook.com/nemetski.kz/">
                    <img src="@/assets/images/common/menu_facebook.svg" alt="" v-if="!style_lab">
                    <img src="@/assets/images/common/menu_facebook_white.svg" alt="" v-else>
                </a>
                <a class="social" href="https://www.youtube.com/channel/UClMjLnw5TNsWnW-q-sRN-uA">
                    <img src="@/assets/images/common/menu_youtube.svg" alt="" v-if="!style_lab">
                    <img src="@/assets/images/common/menu_youtube_white.svg" alt="" v-else>
                </a>
                <a class="social" href="https://t.me/nemetski">
                    <img src="@/assets/images/common/menu_telegram.svg" alt="" v-if="!style_lab">
                    <img src="@/assets/images/common/menu_telegram_white.svg" alt="" v-else>
                </a>
            </div>
            <router-link to="/" class="logo">
                <img src="@/assets/images/common/logo.svg" alt="" v-if="!style_lab">
                <img src="@/assets/images/common/logo_white.svg" alt="" v-else>
            </router-link>
            <div class="languages">
                <p class="popup_head">
                    <span>{{ get_language_links[0].name }}</span>
                    <span class="popup_icon"></span>
                </p>
                <div class="popup right">
                    <span class="popup_icon"></span>
                    <a v-for="(item, index) in get_language_links" :key="index" :href="item.link">{{ item.name }}</a>
                </div>
            </div>
        </div>
        <div class="bottom">
            <nav>
                <ul>
                    <template v-for="(link, index) in links" :key="index">
                        <li class="link" v-if="link.link">
                            <router-link :to="link.link">{{ link.name }}</router-link>
                        </li>
                        <li class="link with_popup" v-else>
                            <p class="popup_head">
                                <span>{{ link.name }}</span>
                                <span class="popup_icon"></span>
                            </p>
                            <div class="popup center">
                                <span class="popup_icon"></span>
                                <router-link v-for="(sub_link, index) in link.sub_links" :key="index"
                                             :to="sub_link.link">{{ sub_link.name }}</router-link>
                            </div>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
        <button class="mobile_open_menu" @click="open_mobile_menu" :class="{ lab: style_lab }">
            <img src="@/assets/images/common/menu.svg" alt="Menu" v-if="!style_lab">
            <img src="@/assets/images/common/menu_blue.svg" alt="Menu" v-else>
        </button>
        <div class="mobile_menu" v-if="mobile_menu_visible" @click="close_mobile_menu_from_left_side">
            <nav>
                <button class="close_menu" @click="close_mobile_menu">
                    <img src="@/assets/images/common/menu_close.svg" alt="">
                </button>
                <router-link to="/" class="logo" @click="close_mobile_menu">
                    <img src="@/assets/images/common/logo_white.svg" alt="Nemetski">
                </router-link>
                <ul>
                    <template v-for="(link, index) in links" :key="index">
                        <li class="link" v-if="link.link">
                            <router-link :to="link.link" @click="close_mobile_menu">{{ link.name }}</router-link>
                        </li>
                        <li class="link with_popup" v-else>
                            <p class="popup_head">
                                <span>{{ link.name }}</span>
                                <span class="popup_icon"></span>
                            </p>
                            <div class="popup center">
                                <span class="popup_icon"></span>
                                <router-link v-for="(sub_link, index) in link.sub_links" :key="index"
                                             :to="sub_link.link" @click="close_mobile_menu">{{ sub_link.name }}</router-link>
                            </div>
                        </li>
                    </template>
                </ul>
                <div class="socials">
                    <a href="https://www.facebook.com/nemetski.kz/">
                        <img src="@/assets/images/common/menu_facebook_white.svg" alt="">
                    </a>
                    <a href="https://www.instagram.com/nemetski.kz/">
                        <img src="@/assets/images/common/menu_instagram_white.svg" alt="">
                    </a>
                    <a href="https://www.youtube.com/channel/UClMjLnw5TNsWnW-q-sRN-uA">
                        <img src="@/assets/images/common/menu_youtube_white.svg" alt="">
                    </a>
                    <a href="https://t.me/nemetski">
                        <img src="@/assets/images/common/menu_telegram_white.svg" alt="">
                    </a>
                </div>
                <div class="contacts">
                    <p class="popup_head">
                        <span>{{ i18n.component_top_menu_contacts }}</span>
                        <span class="popup_icon"></span>
                    </p>
                    <div class="popup left">
                        <span class="popup_icon"></span>
                        <a href="tel:+77778272890">+7-777-827-28-90</a>
                        <a href="mailto:info@nemetski.kz">info@nemetski.kz</a>
                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
import {mapGetters} from "vuex";
import i18n from "../functions/I18n";

export default {
    name: "Top_menu",
    data() {
        return {
            is_mobile: screen.width < 600,
            mobile_menu_visible: false,
            menu_scrolled: false
        }
    },
    methods: {
        open_mobile_menu() {
            this.mobile_menu_visible = true
        },
        close_mobile_menu() {
            this.mobile_menu_visible = false;
        },
        close_mobile_menu_from_left_side(event) {
            if (event.target.classList.contains("mobile_menu")) {
                this.mobile_menu_visible = false;
            }
        }
    },
    computed: {
        i18n() {
            return i18n
        },
        ...mapGetters({
            links: "common/get_header_links",
            style_lab: "common/get_header_style_lab",
            banner: "common/get_banner"
        }),
        get_language_links() {
            let languages = ["", "kz", "dt", "en"],
                languages_names = {
                    kz: "Қаз",
                    dt: "Deu",
                    en: "Eng"
                };
            if (location.hostname.match(/^(kz\.|dt\.|en\.)/)) {
                let current_language = location.hostname.split(".")[0];
                languages = [...new Set([current_language, "", "kz", "dt", "en"])];
            }
            let host = location.hostname.replace(/(kz\.|dt\.|en\.)/, "");
            return languages.map(language => {
                return {
                    name: languages_names[language]? languages_names[language]: "Рус",
                    link: `${location.protocol}//${language? `${language}.`: ""}${host}${this.$router.currentRoute.value.path}`
                }
            });
        }
    },
    mounted() {
        window.addEventListener("scroll", () => {
            this.menu_scrolled = window.scrollY > 400;
        });
    }
}
</script>

<style lang="less" scoped>
header {
    padding: 2rem 0 1rem 0;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
    transition: padding .5s, background .5s;
    width: 100%;
    &.lab {
        background: #38a5c9;
        .top {
            .popup_head {
                color: #fff;
                transition: color .5s;
                .popup_icon {
                    border-top-color: #fff;
                    transition: border-top-color .5s;
                }
            }
        }
        .bottom {
            nav {
                ul {
                    .link {
                        > a, > p {
                            color: #fff;
                        }
                        .popup_head {
                            .popup_icon {
                                border-top-color: #fff;
                                transition: border-top-color .5s;
                            }
                        }
                    }
                }
            }
        }
        .mobile_menu {
            nav {
                background: #38a5c9;
                transition: background .5s;
            }
        }
    }
    &.no_top_padding {
        padding-top: 0;
    }
    @media not (max-width: 600px) {
        &.menu_scroll_show {
            padding: 2rem 0 0 0;
            &.no_top_padding {
                padding-top: 0;
            }
            .top {
                .logo {
                    @keyframes logo_show {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                    display: block;
                    animation: logo_show .5s forwards;
                }
                .socials {
                    @keyframes socials_hide {
                        from {
                            margin-left: 0;
                            opacity: 1;
                            gap: 2rem;
                        }
                        to {
                            margin-left: -100%;
                            opacity: 0;
                            gap: 0;
                        }
                    }
                    z-index: -1;
                    animation: socials_hide .5s forwards;
                }
                .contacts {
                    .popup_socials {
                        display: flex;
                    }
                }
            }
            .bottom {
                margin-top: 2rem;
            }
        }
        &.menu_scroll_hide {
            .top {
                .socials {
                    @keyframes socials_show {
                        from {
                            margin-left: -100%;
                            opacity: 0;
                            gap: 0;
                        }
                        to {
                            margin-left: 0;
                            opacity: 1;
                            gap: 2rem;
                        }
                    }
                    animation: socials_show .5s forwards;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        padding: 0;
    }
    .banner {
        padding: 1rem;
        background: #000;
        display: block;
        text-align: center;
        margin-bottom: 2rem;
        :deep(*) {
            color: #fff;
        }
    }
    .popup {
        top: calc(~"100% + 1.5rem");
        position: absolute;
        background: #fff;
        box-shadow: 0 0 7px 0 rgb(0 0 0 / 20%);
        padding: 1.25rem;
        visibility: hidden;
        transition: all 0s .5s;
        z-index: 2;
        &.left {
            left: 0;
            .popup_icon {
                left: 25%;
            }
        }
        &.right {
            right: 0;
            .popup_icon {
                right: 25%;
            }
            @media screen and (max-width: 600px) {
                right: auto;
                left: 0;
                .popup_icon {
                    right: auto;
                    left: 25%;
                }
            }
        }
        &.center {
            top: calc(~"100% + .5rem");
            left: 50%;
            transform: translateX(-50%);
            .popup_icon {
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        .popup_icon {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
            display: block;
            position: absolute;
            bottom: 100%;
            filter: drop-shadow(0 -4px 4px rgb(0 0 0 / 20%));
        }
    }
    .top, .bottom {
        width: 80%;
        margin: auto;
        min-width: 1024px;
        @media screen and (max-width: 600px) {
            min-width: 100%;
        }
    }
    .top {
        display: grid;
        grid-template-columns: min-content 1fr min-content;
        align-items: center;
        justify-items: center;
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            padding: 2.5rem;
            box-sizing: border-box;
            grid-gap: 1rem;
            align-items: unset;
            justify-items: unset;
        }
        .popup_head {
            font-weight: 700;
            color: #a3a3a3;
            font-size: .75rem;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            cursor: pointer;
            .popup_icon {
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 6px solid #a3a3a3;
                display: block;
                margin-left: .25rem;
            }
        }
        .contacts, .languages {
            display: flex;
            position: relative;
            &:hover {
                .popup {
                    transition: all 0s 0s;
                    visibility: visible;
                }
            }
            .popup > a {
                display: block;
                font-weight: 700;
                color: #000;
                font-size: .9rem;
                & + a {
                    margin-top: .75rem;
                }
            }
            .popup_socials {
                display: none;
                gap: 1rem;
                margin-top: .75rem;
                a {
                    display: block;
                    font-size: .9rem;
                    img {
                        display: block;
                        width: 1.25rem;
                    }
                }
            }
        }
        .socials {
            display: flex;
            gap: 2rem;
            align-items: center;
            grid-area: ~"1 / 2 / 2 / 3";
            @media screen and (max-width: 600px) {
                margin: 0;
            }
            a {
                outline: transparent;
                img {
                    display: block;
                    width: 1.25rem;
                }
            }
        }
        .logo {
            display: none;
            grid-area: ~"1 / 2 / 2 / 3";
            img {
                height: 2rem;
            }
        }
        @media screen and (max-width: 600px) {
            .contacts {
                display: none;
            }
            .socials {
                grid-area: unset;
                grid-row-start: 2;
            }
            .languages {
                grid-row-start: 1;
            }
        }
    }
    .bottom {
        margin: 3rem auto 0 auto;
        transition: all .5s;
        @media screen and (max-width: 600px) {
            display: none;
        }
        nav {
            ul {
                margin: 0;
                display: flex;
                justify-content: space-between;
                list-style: none;
                padding: 0;
                .link {
                    position: relative;
                    &.with_popup {
                        &:hover {
                            .popup {
                                transition: all 0s 0s;
                                visibility: visible;
                            }
                        }
                    }
                    a, p {
                        font-size: .9rem;
                        font-weight: 700;
                        color: #000;
                        white-space: nowrap;
                    }
                    a {
                        display: block;
                        outline: transparent;
                        &:not(:last-child) {
                            margin-bottom: .75rem;
                        }
                    }
                    .popup_head {
                        cursor: pointer;
                        .popup_icon {
                            width: 0;
                            height: 0;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #000;
                            display: block;
                            margin: .75rem auto;
                        }
                    }
                }
            }
        }
    }
    .mobile_open_menu {
        display: none;
        width: 3.5rem;
        height: 3.5rem;
        box-sizing: border-box;
        padding: 1rem;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 1rem;
        &.lab {
            transition: background .5s;
            background: #fff;
        }
        @media screen and (max-width: 600px) {
            display: block;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .mobile_menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: fade(#000, 60%);
        z-index: 11;
        @media scrren and (max-width: 600px) {
            display: block;
        }
        nav {
            width: 75%;
            margin-left: auto;
            height: 100%;
            background: #111;
            padding: 1rem 1rem 1.5rem 1.5rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            position: relative;
            @keyframes show_menu {
                from {
                    right: -75%;
                }
                to {
                    right: 0;
                }
            }
            animation: show_menu .25s linear forwards;
            .close_menu {
                width: 1.25rem;
                height: 1.25rem;
                margin-left: auto;
                display: block;
                img {
                    width: 100%;
                }
            }
            .logo {
                width: 50%;
                margin-top: .5rem;
                margin-bottom: .5rem;
                img {
                    width: 100%;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                .link {
                    position: relative;
                    margin-bottom: 1rem;
                    &.with_popup {
                        &:hover {
                            .popup {
                                transition: all 0s 0s;
                                visibility: visible;
                            }
                        }
                    }
                    .popup {
                        width: calc(~"100% - 2rem");
                        border-radius: .25rem;
                        top: calc(~"100% + 1rem");
                        .popup_icon {
                            filter: none;
                            bottom: 99%;
                        }
                        a {
                            font-weight: 700;
                        }
                    }
                    > a, > p {
                        font-size: .9rem;
                        white-space: nowrap;
                        color: #fff;
                    }
                    a {
                        display: block;
                        &:not(:last-child) {
                            margin-bottom: .75rem;
                        }
                    }
                    .popup_head {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        .popup_icon {
                            width: 0;
                            height: 0;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #fff;
                            display: block;
                            margin-left: .5rem;
                        }
                    }
                }
            }
            .socials {
                display: flex;
                margin-top: auto;
                gap: .5rem;
                align-items: center;
                a {
                    img {
                        display: block;
                        width: 1.75rem;
                    }
                }
            }
            .contacts {
                margin-top: 2rem;
                display: flex;
                position: relative;
                color: #fff;
                font-size: .9rem;
                a {
                    display: block;
                    font-weight: 700;
                    color: #000;
                    font-size: .9rem;
                    &:not(:last-child) {
                        margin-bottom: .75rem;
                    }
                }
                &:hover {
                    .popup {
                        transition: all 0s 0s;
                        visibility: visible;
                    }
                }
                .popup_head {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    .popup_icon {
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 6px solid #fff;
                        display: block;
                        margin-left: .5rem;
                    }
                }
                .popup {
                    width: calc(~"100% - 1rem");
                    border-radius: .25rem;
                    bottom: calc(~"100% + 1rem");
                    top: auto;
                    left: auto;
                    right: 1rem;
                    .popup_icon {
                        filter: none;
                        top: 99%;
                        border-top: 10px solid #fff;
                        border-bottom: none;
                    }
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
</style>