export default {
    actor_spectacles: "Спектакли",
    actors_all_actors: "Список всех актёров театра",
    culture_news_theater_news: "Новости театра",
    culture_news_about: "Новости о культурных событиях Алматы и Казахстана",
    culture_news_tag: "Новости культуры",
    home_actors: "актеры",
    home_actors_view_all: "все актеры",
    home_repertoire: "Репертуар",
    home_repertoire_view_all: "ВЕСЬ РЕПЕРТУАР",
    home_news: "НОВОСТИ",
    home_news_view_all: "Все новости",
    home_news_toggle_all: "Все",
    home_news_toggle_culture: "Новости культуры",
    home_news_toggle_theater: "Новости Немецкого",
    home_subscribe: "Подписаться на наши новости",
    home_subscribe_button: "Подписаться",
    home_subscribe_hint: "Заполняя форму вы даете согласие на хранение и обработку ваших данных.",
    home_subscribe_error: "При выполнении запроса произошла ошибка. Пожалуйста, обновите страницу и попробуйте снова.",
    home_subscribe_success: "Вы успешно подписались на нашу рассылку.",
    home_subscribe_name_placeholder: "Имя",
    not_found_text: "Такой страницы не существует, но вам может быть интересно посетить следующие страницы:",
    not_found_home_name: "Главная",
    production_team_mobile: "Моб.:",
    production_team_cv: "Резюме",
    production_team_portfolio: "Портфолио",
    repertoire_is_for: "репертуар на",
    repertoire_soon: "СКОРО",
    spectacle_buy_a_ticket: "Купить билет",
    spectacle_location: "Место проведения:",
    spectacle_date: "Дата проведения:",
    spectacle_time: "Время проведения:",
    spectacle_price: "Цена:",
    spectacle_actors: "актеры",
    spectacles_text: "Полный список всех спектаклей театра",
    theater_news_culture_news: "Новости культуры",
    theater_news_about: "Следите за всем происходящим в Немецком, анонсами событий и проектами театра",
    theater_news_tag: "Новости немецкого",
    today_spectacle_text: "Следите за обновлениями, скоро будут показаны следующие спектакли:",
    unsubscribe_heading: "Отписаться от рассылки",
    unsubscribe_button: "Отписаться",
    unsubscribe_error: "Указанная электронная почта не найдена в базе.",
    unsubscribe_success: "Вы успешно отписались от нашей рассылки.",
    vacancy_detail_button: "Подробнее",
    vacancy_popup_heading: "Вакансия",
    vacancy_send_error: "При отправке сообщения произошла ошибка. Пожалуйста, обновите страницу и попробуйте снова.",
    vacancy_send_success: "Ваше сообщение успешно отправлено.",
    vacancy_popup_your_cv: "Ваше резюме",
    vacancy_popup_send_button: "Откликнуться",
    vacancy_popup_hint: "Заполняя форму вы даете согласие на хранение и обработку ваших данных.",
    vacancy_popup_name_placeholder: "ФИО",
    vacancy_popup_phone_placeholder: "Телефон",
    vacancy_popup_cv_label: "Загрузить файлы",
    vacancy_popup_cv_files_count: "Выбрано файлов:",
    component_home_repertoire_date: "Дата:",
    component_home_repertoire_time: "Время:",
    component_home_repertoire_soon: "Скоро",
    component_lab_spoiler_go_to_spectacle: "На страницу спектакля",
    component_management_card_contacts: "Контакты:",
    component_management_card_email: "Почта:",
    component_spectacle_date_time: "Время:",
    component_spectacle_date_tickets_in: "Билеты в",
    component_spectacle_date_theater_box_office: "кассе театра",
    component_spectacle_date_tickets: "Билеты",
    component_spectacle_date_entrance: "Вход",
    component_spectacle_date_free: "свободный",
    component_title_link_view: "Посмотреть",
    component_top_menu_contacts: "Контакты",
}