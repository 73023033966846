<template>
    <div id="light_box" v-if="light_box_image">
        <a :href="light_box_image" target="_blank">
            <img :src="require('@/assets/images/common/external-link-black.svg')" alt="">
        </a>
        <button @click="close">
            <img :src="require('@/assets/images/common/close_black.svg')" alt="">
        </button>
        <img :src="light_box_image" :alt="light_box_image">
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Light_box",
    data() {
        return {
            images_root: process.env.VUE_APP_IMAGES_PATH,
        }
    },
    methods: {
        close() {
            this.$store.commit("common/set_light_box", null);
        }
    },
    computed: {
        ...mapGetters({
            light_box_image: "common/get_light_box"
        }),
    }
}
</script>

<style lang="less" scoped>
#light_box {
    position: fixed;
    inset: 0;
    background: fade(#fff, 85%);
    z-index: 9;
    display: flex;
    > img {
        max-width: 90vw;
        max-height: 90vh;
        margin: auto;
    }
    button, a {
        position: absolute;
        top: 1rem;
        img {
            width: 2rem;
            height: 2rem;
        }
    }
    button {
        right: 1rem;
    }
    a {
        right: 4rem;
    }
}
</style>