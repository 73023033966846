export default {
    actor_spectacles: "Спектакльдер",
    actors_all_actors: "Театрдың барлық актерлерінің толық тізімі",
    home_actors: "актерлер",
    home_actors_view_all: "толығырақ",
    home_repertoire: "Репертуар",
    home_repertoire_view_all: "толығырақ",
    not_found_text: "Мұндай бет жоқ, бірақ сіз келесі беттерге кіргіңіз келуі мүмкін:",
    not_found_home_name: "Басты бет",
    repertoire_is_for: "репертуары",
    repertoire_soon: "Жақында",
    spectacle_buy_a_ticket: "Билеттер",
    spectacle_location: "Өткізу орны:",
    spectacle_date: "Өткізу күні:",
    spectacle_time: "Өткізу уақыты:",
    spectacle_price: "Бағасы:",
    spectacle_actors: "актерлер",
    spectacles_text: "Театрдың барлық спектакльдерінің толық тізімі",
    component_home_repertoire_date: "Күні:",
    component_home_repertoire_time: "Уақыты:",
    component_home_repertoire_soon: "Жақында",
    component_lab_spoiler_go_to_spectacle: "Толығырақ",
    component_management_card_contacts: "Байланыстар:",
    component_management_card_email: "Пошта:",
    component_spectacle_date_time: "Уақыты:",
    component_spectacle_date_tickets_in: "Билеттер театр",
    component_spectacle_date_theater_box_office: "кассасында",
    component_spectacle_date_tickets: "Билеттер",
    component_spectacle_date_entrance: "Кіру",
    component_spectacle_date_free: "тегін",
    component_top_menu_contacts: "БАЙЛАНЫСУ",
}