const pages = {
    namespaced: true,
    state: {
        pages: {}
    },
    getters: {
        get_pages(state) {
            return state.pages
        }
    },
    mutations: {
        add_page(state, { key, page }) {
            state.pages[key] = page;
        }
    },
    actions: {},
    modules: {
    }
}
export default pages;