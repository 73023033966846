<template>
    <div id="loader" v-if="visible">
        <div class="letters">
            <Logo_letter :letter="'n'" :animation="'up'"></Logo_letter>
            <Logo_letter v-for="(letter, index) in letters" :key="index" :letter="letter" :child="index + 1" :animation="'left'"></Logo_letter>
        </div>
    </div>
</template>

<script>
import Logo_letter from "@/components/Logo_letter";
import {mapGetters} from "vuex";

export default {
    name: "Loader_popup",
    components: {
        Logo_letter
    },
    data() {
        return {
            letters: ["e", "m", "e", "t", "s", "k", "i"]
        }
    },
    computed: {
        ...mapGetters({
            visible: "common/get_loading"
        })
    }
}
</script>

<style lang="less" scoped>
    #loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: fade(#fff, 95%);
        z-index: 10;
        display: flex;
        .letters {
            margin: auto;
            gap: 7px;
            display: flex;
        }
    }
</style>