<template>
    <div class="letter" :style="{height: letter_size, animationDelay: `${this.child / 4}s`}" :class="this.animation">
        <img :src="require(`@/assets/images/common/logo_${this.letter}.svg`)" :alt="this.letter">
    </div>
</template>

<script>
export default {
    props: {
        letter: {
            required: true
        },
        animation: {
            required: true
        },
        child: Number
    },
    data() {
        return {
            letter_size: 0
        }
    },
    methods: {
        update_letter_size() {
            let size = window.innerWidth / 10;
            size = size > 100? 100: size;
            this.letter_size = `${size}px`;
        }
    },
    mounted() {
        window.addEventListener("resize", () => {
            this.update_letter_size();
        });
        this.update_letter_size();
    }
};
</script>

<style lang="less">
    .letter {
        @keyframes up {
            from {
                opacity: 0;
                transform: translate(0, 50%);
            }
            to {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
        @keyframes left {
            from {
                opacity: 0;
                transform: translate(-50%, 0);
            }
            to {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
        opacity: 0;
        &.up {
            animation: up .5s forwards;
        }
        &.left {
            animation: left .5s forwards;
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>