export default {
    actor_spectacles: "Auffuehrungen",
    actors_all_actors: "Eine vollständige Liste aller Schauspieler des Theaters",
    home_actors: "Schauspieler",
    home_actors_view_all: "mehr",
    home_repertoire: "SPIELPLAN",
    home_repertoire_view_all: "Mehr",
    not_found_text: "Es gibt keine solche Seite, aber Sie könnten an den folgenden Seiten interessiert sein:",
    not_found_home_name: "Hauptseite",
    repertoire_is_for: "Repertoire für",
    repertoire_soon: "bald",
    spectacle_buy_a_ticket: "Ticket kaufen",
    spectacle_location: "Austragungsort:",
    spectacle_date: "Datum der Durchführung:",
    spectacle_time: "Überleitungszeit:",
    spectacle_price: "Preis:",
    spectacle_actors: "Schauspieler",
    spectacles_text: "Eine vollständige Liste aller Theateraufführungen",
    component_home_repertoire_date: "Datum:",
    component_home_repertoire_time: "Uhr:",
    component_home_repertoire_soon: "Bald",
    component_lab_spoiler_go_to_spectacle: "Genauer",
    component_management_card_contacts: "Telefon:",
    component_management_card_email: "Email:",
    component_spectacle_date_time: "Uhr:",
    component_spectacle_date_tickets_in: "an der",
    component_spectacle_date_theater_box_office: "Theaterkasse",
    component_spectacle_date_tickets: "Ticket",
    component_spectacle_date_entrance: "Eintritt",
    component_spectacle_date_free: "frei",
    component_top_menu_contacts: "KONTAKT",
}