<template>
    <footer>
        <div class="logo">
            <img v-animate="{ name: 'fade_scale' }" src="@/assets/images/common/logo.svg" alt="Nemetski">
        </div>
        <nav :style="{'--cols-count': cols_count}">
            <div v-for="(group, index) in footer" :key="index">
                <p v-animate="{ name: 'fade_right' }">{{ group.name }}</p>
                <ul>
                    <li v-animate="{ name: 'fade_up' }" v-for="(content, index) in group.contents" :key="index">
                        <router-link :class="{bold: !is_russian}" :to="content.link" v-if="content.link && !content.external">{{ content.content }}</router-link>
                        <a :class="{bold: !is_russian}" :href="content.link" v-else-if="content.link && content.external">{{ content.content }}</a>
                        <div v-else v-html="content.content"></div>
                    </li>
                </ul>
            </div>
        </nav>
    </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Bottom_Footer",
    data() {
        return {
            is_russian: !location.hostname.match(/(kz\.|dt\.|en\.)/)
        }
    },
    computed: {
        ...mapGetters({
            footer: "common/get_footer"
        }),
        cols_count() {
            return this.footer.length > 7? 6: this.footer.length;
        }
    }
}
</script>

<style lang="less" scoped>
footer {
    width: 80%;
    margin: 7.5rem auto 3rem auto;
    min-width: 1024px;
    @media screen and (max-width: 600px) {
        min-width: 80%;
    }
    .logo {
        width: 100%;
        opacity: .2;
        img {
            width: 100%;
        }
    }
    nav {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: repeat(var(--cols-count), minmax(min-content, 1fr));
        grid-gap: 1rem;
        @media screen and (max-width: 600px) {
            display: block;
            column-count: 2;
            column-gap: 1rem;
        }
        p {
            font-size: .9rem;
            font-weight: bold;
            margin-bottom: .75rem;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            break-inside: avoid-column;
            @media screen and (max-width: 600px) {
                margin-bottom: 1.5rem;
            }
        }
        li {
            font-size: .75rem;
            color: #0a0a0a;
            margin-bottom: .25rem;
            line-height: 1.3rem;
            a.bold {
                font-weight: bold;
            }
        }
    }
}
</style>