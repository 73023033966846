export default function animation(el, value) {
    let observer = new IntersectionObserver((entries) => {
        for (let entry of entries) {
            if (entry.isIntersecting) {
                el.classList.add(value.name);
                if (value.delay) {
                    el.style.setProperty("--delay", value.delay);
                }
            }
        }
    }, {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
    });
    el.classList.add("animation");
    observer.observe(el);
}