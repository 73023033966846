const common = {
    namespaced: true,
    state: {
        loading: false,
        light_box: "",
        header_style_lab: false,
        header_links: [],
        footer: [],
        banner: {
            status: false
        }
    },
    getters: {
        get_loading(state) {
            return state.loading;
        },
        get_light_box(state) {
            return state.light_box;
        },
        get_header_links(state) {
            return state.header_links;
        },
        get_header_style_lab(state) {
            return state.header_style_lab;
        },
        get_footer(state) {
            return state.footer;
        },
        get_banner(state) {
            return state.banner;
        },
    },
    mutations: {
        set_loading(state, value) {
            state.loading = value;
        },
        set_light_box(state, value) {
            state.light_box = value;
        },
        set_header_links(state, value) {
            state.header_links = value;
        },
        set_header_style_lab(state, value) {
            state.header_style_lab = value;
        },
        set_footer(state, value) {
            state.footer = value;
        },
        set_banner(state, value) {
            state.banner = value;
        },
    },
    actions: {
    },
    modules: {
    }
}
export default common;