import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vue3TouchEvents from "vue3-touch-events";
import request from "@/functions/Fetch";
import animation from "@/functions/Animation";
import i18n from "@/functions/I18n";

Promise.all([
    request(`${process.env.VUE_APP_API_PATH}/get_routes`, {}, "POST")
        .then(json => {
            for (let route of json.detail) {
                router.addRoute({
                    path: route.path,
                    component: () => import(`@/views/${route.template}.vue`),
                    meta: {
                        title: route.title
                    },
                })
            }
        }),
    request(`${process.env.VUE_APP_API_PATH}/get_header`, {}, "POST")
        .then(json => {
            store.commit("common/set_header_links", json.detail)
        }),
    request(`${process.env.VUE_APP_API_PATH}/get_footer`, {}, "POST")
        .then(json => {
            store.commit("common/set_footer", json.detail);
        }),
    request(`${process.env.VUE_APP_API_PATH}/get_banner`, {}, "POST")
        .then(json => {
            store.commit("common/set_banner", json.detail);
        })
]).then(() => {
    if (location.hostname.match(/^(kz\.|dt\.|en\.)/)) {
        i18n.language = location.hostname.split(".")[0];
    }
    createApp(App)
        .directive("animate", {
            mounted(el, binding) {
                animation(el, binding.value);
            }
        })
        .use(store)
        .use(router)
        .use(Vue3TouchEvents)
        .mount('#app');
});