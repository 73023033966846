import {createRouter, createWebHistory} from 'vue-router';
import store from "@/store";

const routes = [
    {
        path: '/:pathMatch(.*)',
        component: () => import("@/views/Not_found.vue"),
        meta: {
            title: "404"
        },
    },
    {
        path: "/today_spectacle",
        component: () => import("@/views/Today_spectacle.vue"),
        meta: {
            title: "Ближайший спектакль"
        }
    },
    {
        path: "/unsubscribe",
        component: () => import("@/views/Unsubscribe.vue"),
        meta: {
            title: "Отписаться от рассылки"
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to) => {
    document.title = to.meta.title;
    store.commit("common/set_loading", true);
})

router.afterEach(() => {
    window.scrollTo({
        top: 0
    });
})

export default router
