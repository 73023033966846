import {createStore} from 'vuex';
import common from "@/store/common";
import pages from "@/store/pages";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        common,
        pages
    }
})
